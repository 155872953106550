<template>
    <div class="help">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Marketplace Info</div>
        </div>

        <div class="main">
            <div class="box">
                <div class="box_title">Product Purchases</div>
                <div class="text">
                    Explore an extensive range of products available for purchase. From trendy fashion 
                    items to cutting-edge electronics, our marketplace offers a diverse selection 
                    that caters to different tastes and preferences. Simply browse through the catalog, 
                    select your desired products, and proceed to checkout. We'll take care of the 
                    delivery, ensuring your purchases reach you safely and on time.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Official Services</div>
                <div class="text">
                    In addition to product purchases, our marketplace provides a range of official 
                    services to make your life more convenient. You can easily buy airtime and data 
                    packages for your mobile devices, ensuring you stay connected at all times. 
                    We also offer the ability to make payments for cable TV subscriptions and 
                    electricity bills directly through our app, eliminating the hassle of long 
                    queues and manual processes.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Wallet Functionality</div>
                <div class="text">
                    Our marketplace includes a dedicated wallet feature, allowing users to store 
                    funds securely within the app. You can easily add money to your wallet, providing 
                    a convenient and centralized payment method for your purchases. The wallet also 
                    enables you to view your transaction history, providing a transparent record of 
                    all your financial activities within the marketplace.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Order Tracking</div>
                <div class="text">
                    Once you've made a purchase, you can easily track the status of your orders. 
                    Our app provides real-time updates, keeping you informed about the progress of 
                    your deliveries. You'll receive notifications at each stage, from order confirmation 
                    to dispatch and delivery. This feature ensures a seamless and transparent shopping 
                    experience, giving you peace of mind throughout the process.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Coupon Creation</div>
                <div class="text">
                    As a user of our marketplace, you have the opportunity to create coupons for other 
                    users. These coupons can be shared and redeemed by others to receive discounts on 
                    their purchases. It's a great way to engage with the community, reward loyal 
                    customers, and promote your products or services. Create unique and enticing offers 
                    that enthrall and attract potential buyers, fostering a sense of excitement and 
                    exclusivity.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

export default {
    components: {
        back
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
    .help {
        background-color: white;
    }
    .head {
        text-align: center;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);

        position: relative;
    }
    .back {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 51px);
        box-sizing: border-box;
        overflow: auto;
        background-color: #edeef0;
    }
    .l {
        padding: 50px 0px;
    }

    .box {
        margin-top: 10px;
        padding: 15px;
        background-color: white;
    }
    .box_title {
        font-weight: 500;
    }
    .text {
        margin-top: 15px;
        font-size: 15px;
        line-height: 22px;
        color: gray;
    }
</style>